define("ember-svg-jar/inlined/micro-outline-checkmark-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.95 7.364L0 3.414 1.414 2 3.95 4.536 8.485 0 9.9 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "8",
      "viewBox": "0 0 10 8"
    }
  };
  _exports.default = _default;
});