define("ember-svg-jar/inlined/lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7 13.125a.627.627 0 01-.625-.625v-2c0-.344.281-.625.625-.625s.625.281.625.625v2a.627.627 0 01-.625.625zM14 8.5v6a1.5 1.5 0 01-1.5 1.5h-11A1.5 1.5 0 010 14.5v-6A1.5 1.5 0 011.5 7H2V5C2 2.238 4.25-.01 7.016 0 9.778.01 12 2.284 12 5.047V7h.5A1.5 1.5 0 0114 8.5zM3 7h8V5c0-2.206-1.794-4-4-4S3 2.794 3 5v2zm10 7.5v-6c0-.275-.225-.5-.5-.5h-11c-.275 0-.5.225-.5.5v6c0 .275.225.5.5.5h11c.275 0 .5-.225.5-.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
  _exports.default = _default;
});