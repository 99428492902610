define("ember-svg-jar/inlined/italic-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2.44 1.313h1.12l-1.859 9.624H.471a.33.33 0 00-.219.069.344.344 0 00-.11.205l-.136.656a.31.31 0 00.068.26c.064.082.15.123.26.123h4.102a.346.346 0 00.205-.068.316.316 0 00.123-.205l.11-.657a.31.31 0 00-.07-.26.286.286 0 00-.232-.123H3.424l1.86-9.624h1.257a.33.33 0 00.219-.069.344.344 0 00.11-.205l.136-.656a.31.31 0 00-.068-.26.312.312 0 00-.26-.123H2.576a.346.346 0 00-.205.068.316.316 0 00-.123.205l-.11.657a.31.31 0 00.07.26.286.286 0 00.232.123z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "7",
      "height": "13",
      "viewBox": "0 0 7 13"
    }
  };
  _exports.default = _default;
});