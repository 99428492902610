define("ember-svg-jar/inlined/bar-results-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 0v12.8h7.2v1.6H4.8V16h6.4v-1.6H8.8v-1.6H16V0H0zm1.6 11.2h12.8V1.6H1.6v9.6zm1.6-1.6h1.6V6.4H3.2v3.2zm4 0h1.6V4.8H7.2v4.8zm4 0h1.6V3.2h-1.6v6.4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});