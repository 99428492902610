define("ember-svg-jar/inlined/publish-down-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M11.344 15.933c-3.241 0-6.222-2.6-7.767-4.22 1.551-1.621 4.55-4.23 7.767-4.23 3.239 0 6.22 2.598 7.766 4.22-1.55 1.621-4.549 4.23-7.766 4.23m-9.606-5.215c.173-.23 4.263-5.632 9.606-5.632 5.342 0 9.433 5.402 9.605 5.632l.738.99-.738.99c-.172.23-4.263 5.632-9.605 5.632-5.343 0-9.433-5.402-9.606-5.632L1 11.708l.738-.99z\"/><path fill-rule=\"evenodd\" d=\"M3.823 19.32L21.32 4 20 2.5 2.5 17.82zM28.769 14.329V7H27.4v7.334l-1.432-1.413-.968.955L28.166 17l2.887-3.148-1.015-.907-1.269 1.384z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "31",
      "height": "22",
      "viewBox": "0 0 31 22"
    }
  };
  _exports.default = _default;
});