define("ember-svg-jar/inlined/strikethrough-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.672 7a.316.316 0 00.232-.096.316.316 0 00.096-.232v-.656a.316.316 0 00-.096-.233.316.316 0 00-.232-.096H.328a.316.316 0 00-.232.096.316.316 0 00-.096.233v.656c0 .091.032.168.096.232A.316.316 0 00.328 7h13.344zm-5.688.438c.456.218.784.464.985.738.255.31.383.674.383 1.094 0 .583-.224 1.039-.67 1.367-.447.328-1.035.492-1.764.492-.62 0-1.158-.11-1.613-.328-.547-.274-.82-.666-.82-1.176a.426.426 0 00-.124-.314.426.426 0 00-.314-.123h-.711a.426.426 0 00-.315.123.426.426 0 00-.123.314v.438c0 .82.429 1.476 1.286 1.968.765.438 1.677.656 2.734.656 1.203 0 2.187-.3 2.953-.902.82-.656 1.23-1.55 1.23-2.68 0-.638-.145-1.194-.437-1.668h-2.68zM6.945 5.25c-.674-.255-1.157-.51-1.449-.766a1.679 1.679 0 01-.574-1.298c0-.52.2-.926.601-1.217.401-.292.921-.438 1.559-.438a3.52 3.52 0 011.422.274c.437.218.656.492.656.82 0 .128.046.232.137.314a.44.44 0 00.3.123h.739a.426.426 0 00.314-.123.426.426 0 00.123-.314v-.602c0-.62-.382-1.12-1.148-1.503C8.915.173 8.066 0 7.082 0c-1.13 0-2.05.264-2.762.793-.784.583-1.175 1.422-1.175 2.516 0 .765.218 1.412.656 1.941h3.144z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "13",
      "viewBox": "0 0 14 13"
    }
  };
  _exports.default = _default;
});