define("ember-svg-jar/inlined/jump-forward-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"id-7b\"><use xlink:href=\"#id-6a\"/></clipPath><path transform=\"translate(0 -3)\" id=\"id-6a\" d=\"M15.625 3h-.25c-.206 0-.375.153-.375.34v4.472a.83.83 0 00-.094-.082L8.641 3.21C7.997 2.725 7 3.13 7 3.908v3.34L1.64 3.21C.998 2.725 0 3.13 0 3.908v9.073c0 .777.997 1.185 1.64.698L7 9.613v3.368c0 .777.997 1.185 1.64.698l6.266-4.554A.83.83 0 0015 9.043v4.505c0 .187.169.34.375.34h.25c.206 0 .375-.153.375-.34V3.34c0-.187-.169-.34-.375-.34z\"/></defs><use xlink:href=\"#id-6a\" stroke-width=\"2\" clip-path=\"url(#id-7b)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "16",
      "height": "11",
      "viewBox": "0 0 16 11"
    }
  };
  _exports.default = _default;
});