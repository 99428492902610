define("ember-svg-jar/inlined/edit-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 17.971H2v-12h7.758L8 7.73v4.242h4.242L14 10.213v7.758zm-4-9.414l4.654-4.653 1.413 1.414-4.652 4.653H10V8.557zm7.708-6.293c.188.19.292.44.292.707a.996.996 0 01-.292.707l-.226.226-1.415-1.414.226-.226a1.025 1.025 0 011.415 0zM20 2.971a2.98 2.98 0 00-.878-2.12c-1.134-1.134-3.11-1.135-4.243 0l-3.154 3.154v-.034H0v16h16V8.213l3.12-3.12c.568-.567.88-1.32.88-2.122z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});