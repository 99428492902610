define("ember-svg-jar/inlined/step-forward-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"id-15b\"><use xlink:href=\"#id-14a\"/></clipPath><path transform=\"translate(-5 -2)\" id=\"id-14a\" d=\"M12.639 7.098L6.64 2.226C5.997 1.704 5 2.141 5 2.978v9.773c0 .837.997 1.277 1.64.751L12.64 8.6a.967.967 0 000-1.502z\"/></defs><use xlink:href=\"#id-14a\" stroke-width=\"2\" clip-path=\"url(#id-15b)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "8",
      "height": "12",
      "viewBox": "0 0 8 12"
    }
  };
  _exports.default = _default;
});