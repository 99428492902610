define("ember-svg-jar/inlined/mini-cal-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.5 2c.417 0 .77.146 1.063.438S15 3.083 15 3.5v11c0 .417-.146.77-.438 1.063S13.918 16 13.5 16h-11c-.417 0-.77-.146-1.063-.438S1 14.918 1 14.5v-11c0-.417.146-.77.438-1.063S2.083 2 2.5 2H4V.375c0-.104.036-.193.11-.266S4.27 0 4.374 0h.25c.104 0 .193.036.266.11S5 .27 5 .374V2h6V.375c0-.104.036-.193.11-.266S11.27 0 11.374 0h.25c.104 0 .193.036.266.11S12 .27 12 .374V2zm-11 1c-.146 0-.266.047-.36.14S2 3.355 2 3.5V5h12V3.5c0-.146-.047-.266-.14-.36S13.645 3 13.5 3zm11 12c.146 0 .266-.047.36-.14s.14-.214.14-.36V6H2v8.5c0 .146.047.266.14.36s.214.14.36.14zm-7.875-5h-1.25c-.104 0-.193-.036-.266-.11S4 9.73 4 9.626v-1.25c0-.104.036-.193.11-.266S4.27 8 4.374 8h1.25c.104 0 .193.036.266.11S6 8.27 6 8.374v1.25c0 .104-.036.193-.11.266S5.73 10 5.626 10zm3 0h-1.25c-.104 0-.193-.036-.266-.11S7 9.73 7 9.626v-1.25c0-.104.036-.193.11-.266S7.27 8 7.374 8h1.25c.104 0 .193.036.266.11S9 8.27 9 8.374v1.25c0 .104-.036.193-.11.266S8.73 10 8.626 10zm3 0h-1.25c-.104 0-.193-.036-.266-.11S10 9.73 10 9.626v-1.25c0-.104.036-.193.11-.266S10.27 8 10.374 8h1.25c.104 0 .193.036.266.11s.109.16.109.265v1.25c0 .104-.036.193-.11.266s-.16.109-.265.109zm-3 3h-1.25c-.104 0-.193-.036-.266-.11S7 12.73 7 12.626v-1.25c0-.104.036-.193.11-.266S7.27 11 7.374 11h1.25c.104 0 .193.036.266.11s.109.16.109.265v1.25c0 .104-.036.193-.11.266S8.73 13 8.626 13zm-3 0h-1.25c-.104 0-.193-.036-.266-.11S4 12.73 4 12.626v-1.25c0-.104.036-.193.11-.266S4.27 11 4.374 11h1.25c.104 0 .193.036.266.11s.109.16.109.265v1.25c0 .104-.036.193-.11.266S5.73 13 5.626 13zm6 0h-1.25c-.104 0-.193-.036-.266-.11S10 12.73 10 12.626v-1.25c0-.104.036-.193.11-.266s.16-.109.265-.109h1.25c.104 0 .193.036.266.11s.109.16.109.265v1.25c0 .104-.036.193-.11.266s-.16.109-.265.109z\"/>",
    "attrs": {
      "height": "16",
      "viewBox": "0 0 16 16",
      "width": "16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});