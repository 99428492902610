define("ember-svg-jar/inlined/duplicate-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.95 2.576L14.925.549A1.875 1.875 0 0013.598 0H6.875C5.839 0 5 .84 5 1.875V3.75H1.875C.839 3.75 0 4.59 0 5.625v12.5C0 19.161.84 20 1.875 20h8.75c1.036 0 1.875-.84 1.875-1.875V16.25h3.125c1.036 0 1.875-.84 1.875-1.875V3.902c0-.498-.198-.975-.55-1.326zm-3.2-1.307a.62.62 0 01.29.164l2.027 2.027c.08.08.137.18.164.29H13.75V1.27zm-2.5 16.856c0 .345-.28.625-.625.625h-8.75a.626.626 0 01-.625-.625v-12.5c0-.345.28-.625.625-.625H5v9.375c0 1.036.84 1.875 1.875 1.875h4.375v1.875zm5-3.75c0 .345-.28.625-.625.625h-8.75a.626.626 0 01-.625-.625v-12.5c0-.345.28-.625.625-.625H12.5v2.813a.94.94 0 00.938.937h2.812v9.375z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "20",
      "viewBox": "0 0 18 20"
    }
  };
  _exports.default = _default;
});