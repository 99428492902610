define("ember-svg-jar/inlined/file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.002 3.445l-2.95-2.95A1.687 1.687 0 008.863 0H1.686A1.693 1.693 0 000 1.69v14.623C0 17.244.756 18 1.687 18H11.81c.932 0 1.687-.756 1.687-1.687V4.64c0-.447-.179-.879-.495-1.195zm-.795.798a.553.553 0 01.148.26H8.998V1.146a.553.553 0 01.26.147zm-.397 12.632H1.687a.564.564 0 01-.562-.562V1.69c0-.31.253-.563.562-.563h6.186v3.656c0 .467.377.843.844.843h3.656v10.686c0 .31-.253.562-.563.562z\"/>",
    "attrs": {
      "height": "18",
      "viewBox": "0 0 14 18",
      "width": "14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});