define("ember-svg-jar/inlined/envelope-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14.5 0c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v9c0 .417-.146.77-.438 1.063A1.447 1.447 0 0114.5 12h-13c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 010 10.5v-9C0 1.083.146.73.438.437A1.447 1.447 0 011.5 0h13zm-13 1a.487.487 0 00-.36.14.487.487 0 00-.14.36v1.281c.73.625 2.302 1.896 4.719 3.813l.281.25c.417.354.75.604 1 .75.396.27.73.406 1 .406s.604-.135 1-.406c.23-.146.552-.396.969-.75l.312-.25C12.698 4.677 14.271 3.406 15 2.78V1.5a.487.487 0 00-.14-.36.487.487 0 00-.36-.14h-13zm13 10a.487.487 0 00.36-.14.487.487 0 00.14-.36V4.094c-.73.583-2.094 1.677-4.094 3.281l-.312.25c-.5.417-.896.719-1.188.906C8.886 8.844 8.416 9 8 9c-.417 0-.885-.156-1.406-.469-.313-.187-.709-.49-1.188-.906l-.312-.25L1 4.094V10.5a.49.49 0 00.14.36.49.49 0 00.36.14h13z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
  _exports.default = _default;
});