define("ember-svg-jar/inlined/schedule-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M12 0v1.6H4V0H2.4v1.6H0V16h16V1.6h-2.4V0H12zM1.6 14.4h12.8V3.2H1.6v11.2zm3.2-1.6h1.6V5.6H4v1.6h.8v5.6zm3.285-.358l1.43.716 3.2-6.4a.8.8 0 00-.714-1.158h-4v1.6h2.704l-2.62 5.242z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});