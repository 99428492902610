define("ember-svg-jar/inlined/company-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6 3.344v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11A.362.362 0 014 4.594v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25c.104 0 .193.037.266.11A.362.362 0 016 3.344zm3.625-.375a.36.36 0 01.266.11c.073.072.109.16.109.265v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11A.362.362 0 018 4.594v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25zm-4 3a.36.36 0 01.266.11c.073.072.109.16.109.265v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11A.362.362 0 014 7.594v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25zm4 0a.36.36 0 01.266.11c.073.072.109.16.109.265v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11A.362.362 0 018 7.594v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25zm-4 3a.36.36 0 01.266.11c.073.072.109.16.109.265v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25zm4 0a.36.36 0 01.266.11c.073.072.109.16.109.265v1.25a.362.362 0 01-.11.265.362.362 0 01-.265.11h-1.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-1.25a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25zM14 15.375V16H0v-.625a.36.36 0 01.11-.266A.362.362 0 01.374 15H1V.75c0-.208.073-.385.219-.531A.723.723 0 011.75 0h10.5c.208 0 .385.073.531.219A.723.723 0 0113 .75V15h.625a.36.36 0 01.266.11c.073.072.109.16.109.265zM12 15V1H2v14h4v-2.656a.36.36 0 01.11-.266.362.362 0 01.265-.11h1.25c.104 0 .193.037.266.11a.362.362 0 01.109.266V15h4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
  _exports.default = _default;
});