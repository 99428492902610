define("ember-svg-jar/inlined/layer-plus-light-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.75 3H13V.25a.269.269 0 00-.25-.25h-.5a.247.247 0 00-.25.25V3H9.25a.246.246 0 00-.25.25v.5c0 .156.094.25.25.25H12v2.75c0 .156.094.25.25.25h.5c.125 0 .25-.094.25-.25V4h2.75c.125 0 .25-.094.25-.25v-.5a.269.269 0 00-.25-.25zm-.219 8.469l-3.125-1.344 3.125-1.344a.48.48 0 00.25-.656c-.094-.25-.406-.375-.656-.281l-7.063 3.031c-.062.031-.093.031-.156 0l-6.5-2.781L7.438 5.5c.28-.125.375-.406.28-.688a.516.516 0 00-.687-.25L.438 7.376C.156 7.5 0 7.781 0 8.094c0 .312.156.594.438.687l3.124 1.344-3.124 1.344c-.282.125-.438.406-.438.719 0 .312.156.593.438.687L7.5 15.906c.156.063.313.125.469.125.187 0 .343-.062.5-.125l7.062-3.031c.281-.094.438-.375.438-.688a.716.716 0 00-.438-.718zm-7.469 3.5c-.062.031-.093.031-.156 0l-6.5-2.781 3.469-1.5L7.5 11.812c.156.063.313.126.5.126a.882.882 0 00.469-.126l2.656-1.124 3.438 1.5-6.5 2.78z\"/>",
    "attrs": {
      "width": "16",
      "height": "17",
      "viewBox": "0 0 16 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});