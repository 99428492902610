define("ember-svg-jar/inlined/expand-alt-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d=\"M212.7 315.3L120 408l32.9 31c15.1 15.1 4.4 41-17 41h-112C10.7 480 0 469.3 0 456V344c0-21.4 25.8-32.1 40.9-17L72 360l92.7-92.7c6.2-6.2 16.4-6.2 22.6 0l25.4 25.4c6.2 6.2 6.2 16.4 0 22.6zm22.6-118.6L328 104l-32.9-31C280 57.9 290.7 32 312 32h112c13.3 0 24 10.7 24 24v112c0 21.4-25.8 32.1-40.9 17L376 152l-92.7 92.7c-6.2 6.2-16.4 6.2-22.6 0l-25.4-25.4c-6.2-6.2-6.2-16.4 0-22.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 448 512"
    }
  };
  _exports.default = _default;
});