define("ember-svg-jar/inlined/micro-arrow-down-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.95 6.364L0 1.414 1.414 0 4.95 3.536 8.485 0 9.9 1.414z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7"
    }
  };
  _exports.default = _default;
});