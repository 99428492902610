define("ember-svg-jar/inlined/list-search-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.766 14.63l-2.03-2.03a3.973 3.973 0 00.665-2.202c0-1.639-.99-3.042-2.402-3.66V0H0v14.4h10.399c.815 0 1.57-.247 2.201-.666l2.031 2.031A.798.798 0 0015.2 16c.205 0 .41-.077.567-.235a.806.806 0 000-1.135zM1.6 1.6H10.4v4.796a4.01 4.01 0 00-1.6.337v-.337H3.2V8h4.02a3.948 3.948 0 00-.739 1.604h-3.28V11.2h3.28a4.02 4.02 0 00.743 1.6H1.6V1.6zM8 10.398A2.4 2.4 0 0110.4 8a2.4 2.4 0 012.398 2.398 2.4 2.4 0 01-2.398 2.398A2.4 2.4 0 018 10.398zM3.2 3.2h5.6v1.6H3.2V3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});