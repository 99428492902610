define("ember-svg-jar/inlined/customize-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M2.4 6.397c-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8m.797-3.054A2.395 2.395 0 014.8 5.597a2.396 2.396 0 01-1.603 2.254V16h-1.6V7.847A2.396 2.396 0 010 5.597c0-1.04.67-1.92 1.597-2.252V0h1.6v3.343zM8 11.197c-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8zm.797-3.054a2.395 2.395 0 011.603 2.254 2.396 2.396 0 01-1.603 2.254V16h-1.6v-3.353a2.396 2.396 0 01-1.597-2.25c0-1.04.67-1.92 1.597-2.252V0h1.6v8.143zM16 5.597a2.396 2.396 0 01-1.603 2.254V16h-1.6V7.847a2.396 2.396 0 01-1.597-2.25c0-1.04.67-1.92 1.597-2.252V0h1.6v3.343A2.395 2.395 0 0116 5.597zm-2.4.8c-.44 0-.8-.36-.8-.8 0-.44.36-.8.8-.8.44 0 .8.36.8.8 0 .44-.36.8-.8.8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});