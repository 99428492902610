define("ember-svg-jar/inlined/profile-card-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14.5 0c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062v9c0 .417-.146.77-.438 1.063A1.447 1.447 0 0114.5 12h-13c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 010 10.5v-9C0 1.083.146.73.438.437A1.447 1.447 0 011.5 0h13zm.5 10.5v-9a.487.487 0 00-.14-.36.487.487 0 00-.36-.14h-13a.487.487 0 00-.36.14.487.487 0 00-.14.36v9a.49.49 0 00.14.36.49.49 0 00.36.14h13a.487.487 0 00.36-.14.487.487 0 00.14-.36zM13.625 8h-3.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-.25a.36.36 0 01.11-.266.362.362 0 01.265-.109h3.25a.36.36 0 01.266.11c.073.072.109.16.109.265v.25a.362.362 0 01-.11.266.362.362 0 01-.265.109zm0-2h-3.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-.25a.36.36 0 01.11-.266.362.362 0 01.264-.109h3.25a.36.36 0 01.266.11c.073.072.109.16.109.265v.25a.362.362 0 01-.11.266.362.362 0 01-.265.109zm0-2h-3.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-.25a.36.36 0 01.11-.266.362.362 0 01.264-.109h3.25a.36.36 0 01.266.11c.073.072.109.16.109.265v.25a.362.362 0 01-.11.266.362.362 0 01-.265.109zM7.812 5.875c.355.125.641.339.86.64.219.303.328.641.328 1.016v1.094c0 .375-.135.698-.406.969-.271.27-.594.406-.969.406h-4.25c-.375 0-.698-.135-.969-.406A1.323 1.323 0 012 8.625V7.531c0-.375.11-.713.328-1.015a1.76 1.76 0 01.86-.641l.187-.063A2.408 2.408 0 013 4.5c0-.688.245-1.276.734-1.766A2.407 2.407 0 015.5 2a2.41 2.41 0 011.766.734C7.756 3.224 8 3.812 8 4.5c0 .48-.125.917-.375 1.313l.188.062zM4 4.5c0 .417.146.77.438 1.063.291.291.645.437 1.062.437.417 0 .77-.146 1.063-.438C6.854 5.271 7 4.918 7 4.5c0-.417-.146-.77-.438-1.063A1.447 1.447 0 005.5 3c-.417 0-.77.146-1.063.438A1.447 1.447 0 004 4.5zm4 4.125V7.531a.709.709 0 00-.14-.437.869.869 0 00-.36-.281l-.656-.22a2.42 2.42 0 01-2.688 0l-.656.22a.869.869 0 00-.36.28.709.709 0 00-.14.438v1.094a.36.36 0 00.11.266c.072.073.16.109.265.109h4.25a.362.362 0 00.266-.11A.362.362 0 008 8.625z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
  _exports.default = _default;
});