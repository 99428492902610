define("ember-svg-jar/inlined/success-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.719 9A8.719 8.719 0 11.28 9 8.719 8.719 0 0117.72 9zM7.99 13.616l6.47-6.468a.563.563 0 000-.796l-.796-.795a.562.562 0 00-.796 0l-5.275 5.275L5.13 8.37a.563.563 0 00-.796 0l-.795.796a.563.563 0 000 .795l3.656 3.656c.22.22.576.22.795 0z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});