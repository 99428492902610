define("ember-svg-jar/inlined/phone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#212631\" fill-rule=\"evenodd\" d=\"M.654 1.406a.945.945 0 00-.465.315.78.78 0 00-.191.506c0 2.278.574 4.393 1.723 6.343a12.301 12.301 0 004.484 4.485 12.24 12.24 0 006.29 1.695c.2 0 .377-.064.532-.191a.945.945 0 00.315-.465l.629-2.762a.744.744 0 00-.069-.574.97.97 0 00-.424-.41L10.525 9.09a.87.87 0 00-.547-.041.894.894 0 00-.464.287L8.31 10.812A9.72 9.72 0 015.768 8.98a9.72 9.72 0 01-1.833-2.543l1.477-1.203A.894.894 0 005.7 4.77a.87.87 0 00-.04-.547L4.4 1.27a.97.97 0 00-.41-.424.744.744 0 00-.574-.069l-2.762.63zm11.84 12.469c-2.096 0-4.047-.529-5.851-1.586a11.508 11.508 0 01-4.184-4.184A11.377 11.377 0 01.873 2.254l2.734-.629 1.258 2.953L2.87 6.191c.656 1.422 1.413 2.562 2.27 3.418.856.857 1.996 1.614 3.418 2.27l1.613-1.996 2.953 1.258-.629 2.734z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15"
    }
  };
  _exports.default = _default;
});