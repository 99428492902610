define("ember-svg-jar/inlined/underline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 13.672v-.656c0-.092.032-.169.096-.233a.316.316 0 01.232-.095h11.594c.091 0 .168.031.232.095a.316.316 0 01.096.233v.656a.316.316 0 01-.096.232.316.316 0 01-.232.096H.328a.316.316 0 01-.232-.096.316.316 0 01-.096-.232zM7.602.328c0-.091.031-.168.095-.232A.316.316 0 017.93 0h3.718c.092 0 .17.032.233.096a.316.316 0 01.096.232v.656a.316.316 0 01-.096.233.316.316 0 01-.233.095h-1.12V7.11c0 1.422-.41 2.525-1.231 3.309-.802.747-1.869 1.121-3.2 1.121-1.33 0-2.378-.374-3.144-1.121-.82-.766-1.23-1.869-1.23-3.309V1.313H.602a.316.316 0 01-.233-.096.316.316 0 01-.096-.233V.328C.273.237.305.16.369.096A.316.316 0 01.602 0H4.32c.091 0 .169.032.233.096a.316.316 0 01.095.232v.656a.316.316 0 01-.095.233.316.316 0 01-.233.095H3.227V7.11c0 1.021.25 1.782.752 2.284.5.5 1.216.752 2.146.752.93 0 1.65-.26 2.16-.78.51-.52.766-1.28.766-2.283v-5.77H7.93a.316.316 0 01-.233-.095.316.316 0 01-.095-.233V.329z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14"
    }
  };
  _exports.default = _default;
});