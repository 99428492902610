define("ember-svg-jar/inlined/user-address-book-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.625 5A.376.376 0 0014 4.625v-1.25A.376.376 0 0013.625 3H13V2c0-1.103-.897-2-2-2H2C.897 0 0 .897 0 2v12c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2v-1h.625a.376.376 0 00.375-.375v-1.25a.376.376 0 00-.375-.375H13V9h.625A.376.376 0 0014 8.625v-1.25A.376.376 0 0013.625 7H13V5h.625zM12 14c0 .55-.45 1-1 1H2c-.55 0-1-.45-1-1V2c0-.55.45-1 1-1h9c.55 0 1 .45 1 1v12zM6.5 8.5a2.5 2.5 0 10.001-4.999A2.5 2.5 0 006.5 8.5zm0-4a1.5 1.5 0 110 3 1.5 1.5 0 010-3zM7.963 9c-.61 0-.763.219-1.463.219-.7 0-.853-.219-1.463-.219-.662 0-1.306.294-1.68.856A2.092 2.092 0 003 11.028v1.222c0 .137.112.25.25.25h.5a.25.25 0 00.25-.25v-1.222c0-.219.066-.431.188-.612.175-.26.493-.413.853-.413.387 0 .65.219 1.462.219.81 0 1.072-.219 1.463-.219.359 0 .678.156.853.413.122.18.187.393.187.612v1.222c0 .137.113.25.25.25h.5a.25.25 0 00.25-.25v-1.222c0-.434-.131-.837-.356-1.172C9.266 9.294 8.622 9 7.962 9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16"
    }
  };
  _exports.default = _default;
});