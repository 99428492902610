define("ember-svg-jar/inlined/clock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm6.857 8A6.857 6.857 0 018 14.857 6.857 6.857 0 011.143 8 6.857 6.857 0 018 1.143 6.857 6.857 0 0114.857 8zm-4.254 3.353L8.148 9.407A.411.411 0 018 9.087V3.824c0-.217.163-.395.363-.395h.423c.2 0 .363.178.363.395V8.65l2.13 1.691a.42.42 0 01.08.554l-.249.373a.346.346 0 01-.507.086z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});