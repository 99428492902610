define("ember-svg-jar/inlined/exit-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16 0v12h-5.6v.306l1.6.8V14.4h2.4V16H1.6v-1.6H4v-1.294l1.6-.8V12H0V0h16zm-1.6 1.6H1.6v8.8h12.8V1.6zm-4 12.495l-1.6-.8V12H7.2v1.295l-1.6.8v.305h4.8v-.305zM4.566 8.566L6.4 6.732V8H8V4H4v1.6h1.269L3.434 7.434l1.132 1.132z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});