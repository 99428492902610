define("ember-svg-jar/inlined/jump-backward-light-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.375 11.998h.25A.376.376 0 001 11.624V6.697a.86.86 0 00.093.09l6.264 4.98c.644.538 1.64.088 1.64-.768v-3.68l5.359 4.448c.643.538 1.64.088 1.64-.768V1c0-.856-.997-1.306-1.64-.768L8.998 4.716V1.004C8.998.148 8-.3 7.358.236L1.092 5.25A.86.86 0 001 5.34V.377A.376.376 0 00.625.002h-.25A.376.376 0 000 .376v11.248c0 .206.169.374.375.374zm8.763-5.98l.006-.002.007-.004 5.845-5.01v9.997L9.144 6.025l-.003-.003-.003-.003zm-7.232-.012l6.086-4.998.003-.004.003-.003V11l-.003-.003-.003-.004-6.086-4.96v-.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
  _exports.default = _default;
});