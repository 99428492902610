define("ember-svg-jar/inlined/compress-alt-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d=\"M4.7 427.3L104 328l-32.9-31C56 281.9 66.7 256 88 256h112c13.3 0 24 10.7 24 24v112c0 21.4-25.8 32.1-40.9 17L152 376l-99.3 99.3c-6.2 6.2-16.4 6.2-22.6 0L4.7 449.9c-6.2-6.2-6.2-16.4 0-22.6zM443.3 84.7L344 184l32.9 31c15.1 15.1 4.4 41-17 41h-112c-13.2 0-23.9-10.7-23.9-24V120c0-21.4 25.8-32.1 40.9-17l31.1 33 99.3-99.3c6.2-6.2 16.4-6.2 22.6 0l25.4 25.4c6.2 6.2 6.2 16.4 0 22.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 448 512"
    }
  };
  _exports.default = _default;
});