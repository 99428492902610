define("ember-svg-jar/inlined/pencil-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17.332 1.968c.445.446.668.979.668 1.6 0 .62-.223 1.154-.668 1.599L4.96 17.539l-4.007.457a.847.847 0 01-.703-.246.847.847 0 01-.246-.703l.457-4.007L12.833.668a2.182 2.182 0 011.6-.668c.62 0 1.153.223 1.599.668l1.3 1.3zm-3.456 5.005l-2.849-2.85-7.689 7.69H4.54v1.647h1.647v1.202l7.69-7.69zM4.54 16.309l.686-.686V14.42H3.58v-1.647H2.377l-.686.686-.206 2.026 1.03 1.03 2.025-.207zM16.382 4.467c.206-.206.309-.463.309-.772s-.103-.566-.31-.772l-1.304-1.305a1.048 1.048 0 00-.772-.309c-.309 0-.566.103-.772.31l-1.716 1.715 2.849 2.85 1.716-1.717z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});