define("ember-svg-jar/inlined/bold-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.84 5.679c.626.125 1.142.45 1.55.977.406.527.61 1.17.61 1.929 0 .759-.227 1.433-.682 2.022a3.368 3.368 0 01-1.648 1.179c-.492.143-1.136.214-1.931.214H.34a.34.34 0 01-.242-.094A.301.301 0 010 11.68v-.643a.3.3 0 01.1-.228.34.34 0 01.24-.094h.967V1.286H.34a.34.34 0 01-.242-.094A.301.301 0 010 .964V.321C0 .232.033.156.1.094A.34.34 0 01.34 0h5.171c.947 0 1.686.116 2.216.348.53.232.957.585 1.279 1.058.322.473.483 1.023.483 1.648 0 .571-.142 1.089-.427 1.553a2.78 2.78 0 01-1.221 1.072zM3.126 1.393v3.643h2.557c.55 0 1.013-.174 1.392-.523.379-.348.568-.772.568-1.272a1.904 1.904 0 00-.185-.964 1.398 1.398 0 00-.639-.643c-.322-.16-.757-.241-1.307-.241H3.125zm3.835 9.053c.36-.142.649-.392.867-.75.218-.357.326-.758.326-1.205 0-.625-.198-1.125-.596-1.5-.398-.375-.928-.562-1.591-.562H3.125v4.178h2.699c.473 0 .852-.053 1.136-.16z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "12",
      "viewBox": "0 0 10 12"
    }
  };
  _exports.default = _default;
});