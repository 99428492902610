define("ember-svg-jar/inlined/file-generic-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.008 3.695c.328.328.492.727.492 1.196v11.671c0 .47-.164.868-.492 1.196a1.627 1.627 0 01-1.195.492H1.687c-.468 0-.867-.164-1.195-.492A1.627 1.627 0 010 16.563V1.938c0-.47.164-.868.492-1.196A1.627 1.627 0 011.688.25h7.171c.47 0 .868.164 1.196.492l2.953 2.953zm-.809.809L9.246 1.55A.938.938 0 009 1.375V4.75h3.375a.938.938 0 00-.176-.246zm-.386 12.621a.547.547 0 00.404-.158.547.547 0 00.158-.404V5.874H8.719a.814.814 0 01-.598-.246.814.814 0 01-.246-.598V1.375H1.687a.547.547 0 00-.404.158.547.547 0 00-.158.405v14.624c0 .165.053.3.158.405a.55.55 0 00.405.158h10.124zm-1.688-8.578v.281a.407.407 0 01-.123.299.407.407 0 01-.299.123H3.797a.407.407 0 01-.299-.123.407.407 0 01-.123-.299v-.281c0-.117.041-.217.123-.299a.407.407 0 01.299-.123h5.906c.117 0 .217.041.299.123a.407.407 0 01.123.299zm0 2.25v.281a.407.407 0 01-.123.299.407.407 0 01-.299.123H3.797a.407.407 0 01-.299-.123.407.407 0 01-.123-.299v-.281c0-.117.041-.217.123-.299a.407.407 0 01.299-.123h5.906c.117 0 .217.041.299.123a.407.407 0 01.123.299zm0 2.25v.281a.407.407 0 01-.123.299.407.407 0 01-.299.123H3.797a.407.407 0 01-.299-.123.407.407 0 01-.123-.299v-.281c0-.117.041-.217.123-.299a.407.407 0 01.299-.123h5.906c.117 0 .217.041.299.123a.407.407 0 01.123.299z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "19",
      "viewBox": "0 0 14 19"
    }
  };
  _exports.default = _default;
});