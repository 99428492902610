define("ember-svg-jar/inlined/step-forward-light-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.639 5.098L1.64.226C.997-.296 0 .141 0 .978v9.773c0 .837.997 1.277 1.64.751L7.64 6.6a.967.967 0 000-1.502zm-.64.748l-6 4.905V.978l6 4.868z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "12",
      "viewBox": "0 0 8 12"
    }
  };
  _exports.default = _default;
});