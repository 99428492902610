define("ember-svg-jar/inlined/gavel-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M1 11.333v.81H.19v4.047h8.096v-4.047h-.81v-.81H1zm.81 2.43h.81v-.81h3.237v.81h.81v.809H1.809v-.81zm4.201-6.1l-1.57-1.57a6.1 6.1 0 013.434-3.435l1.57 1.57a6.104 6.104 0 01-3.434 3.435zm3.751-.83c.346-.429.644-.896.894-1.394l.294.294 1.145-1.145-4.58-4.58-1.144 1.145.294.295A7.69 7.69 0 003.228 4.88l-.292-.292-1.145 1.145 4.58 4.58 1.145-1.145-.294-.293c.5-.25.968-.55 1.397-.896l6.63 6.598 1.142-1.148-6.629-6.597z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17"
    }
  };
  _exports.default = _default;
});