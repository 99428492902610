define("ember-svg-jar/inlined/parking-circle-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M268.1 127.91H176c-4.42 0-8 3.58-8 8v240c0 4.42 3.58 8 8 8h16c4.42 0 8-3.58 8-8v-72h72c51.43 0 92.68-44.32 87.57-96.78-4.45-45.71-45.54-79.22-91.47-79.22zm3.9 144h-72v-112h72c30.88 0 56 25.12 56 56s-25.12 56-56 56zM248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 496 512"
    }
  };
  _exports.default = _default;
});