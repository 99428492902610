define("ember-svg-jar/inlined/pad-lock-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.39 4.244l-.077-.893-.893.068c-4.532.336-6.385-2.233-6.453-2.33L8.223 0l-.758 1.085c-.073.106-1.94 2.665-6.458 2.338l-.893-.067-.087.888c-.01.11-.874 11.144 7.927 13.684l.255.072.254-.072c8.8-2.54 7.937-13.573 7.927-13.684zm-8.181 11.86c-5.891-1.878-6.415-8.45-6.4-10.837 3.346 0 5.396-1.383 6.4-2.319 1.003.936 3.049 2.319 6.395 2.319-.01 2.377-.571 8.978-6.395 10.836zM5.482 8.867c0 1.186.763 2.185 1.82 2.559v1.988h1.819v-1.988a2.723 2.723 0 001.82-2.559A2.731 2.731 0 008.214 6.14a2.736 2.736 0 00-2.732 2.727zm3.634 0a.908.908 0 11-1.816.001.908.908 0 011.816-.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "18",
      "viewBox": "0 0 17 18"
    }
  };
  _exports.default = _default;
});