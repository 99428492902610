define("ember-svg-jar/inlined/binoculars-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.4 0v3.2h-.816v1.6H6.416V3.2h-.815V0H1.6v3.2H.784v8H0V16h7.2v-4.8h-.784V9.598h3.168V11.2H8.8V16H16v-4.8h-.784v-8H14.4V0h-4zM12 3.2h.8V1.6H12v1.6zm-8.8 0H4V1.6h-.8v1.6zm7.984 6.398h.02V4.8h2.412v6.4h-2.432V9.598zM2.384 4.8h2.391v4.798h.041V11.2H2.384V4.8zm4.032 3.198h3.168V6.4H6.416v1.598zM10.4 14.4h4v-1.6h-4v1.6zm-8.8 0h4v-1.6h-4v1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});