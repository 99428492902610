define("ember-svg-jar/inlined/notice-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 1.161c4.305 0 7.839 3.487 7.839 7.839A7.837 7.837 0 019 16.839 7.837 7.837 0 011.161 9 7.838 7.838 0 019 1.161zM9 0C4.03 0 0 4.031 0 9a9 9 0 009 9 9 9 0 009-9c0-4.969-4.03-9-9-9zm-.417 4.355h.834c.248 0 .445.206.435.453l-.254 6.097a.435.435 0 01-.435.418h-.326a.436.436 0 01-.435-.418l-.254-6.097a.436.436 0 01.435-.453zM9 12.048a1.016 1.016 0 100 2.033 1.016 1.016 0 000-2.033z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});