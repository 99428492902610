define("ember-svg-jar/inlined/collapse-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.625 5a.362.362 0 00.266-.11.362.362 0 00.109-.265v-.25a.362.362 0 00-.11-.266.362.362 0 00-.265-.109H10V.375a.362.362 0 00-.11-.266A.362.362 0 009.625 0h-.25a.362.362 0 00-.266.11A.362.362 0 009 .375V4.25c0 .208.073.385.219.531A.723.723 0 009.75 5h3.875zM5 4.25V.375a.362.362 0 00-.11-.266A.362.362 0 004.626 0h-.25a.362.362 0 00-.266.11.362.362 0 00-.109.265V4H.375a.362.362 0 00-.266.11.362.362 0 00-.109.265v.25a.36.36 0 00.11.266C.182 4.964.27 5 .375 5H4.25a.723.723 0 00.531-.219A.723.723 0 005 4.25zm0 9.375V9.75a.723.723 0 00-.219-.531A.723.723 0 004.25 9H.375a.362.362 0 00-.266.11.362.362 0 00-.109.265v.25a.36.36 0 00.11.266c.072.073.16.109.265.109H4v3.625a.36.36 0 00.11.266c.072.073.16.109.265.109h.25a.362.362 0 00.266-.11.362.362 0 00.109-.265zm5 0V10h3.625a.362.362 0 00.266-.11.362.362 0 00.109-.265v-.25a.362.362 0 00-.11-.266.362.362 0 00-.265-.109H9.75a.723.723 0 00-.531.219A.723.723 0 009 9.75v3.875a.36.36 0 00.11.266c.072.073.16.109.265.109h.25a.362.362 0 00.266-.11.362.362 0 00.109-.265z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});