define("ember-svg-jar/inlined/user-thin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M9.05 6.563c.639 0 1.2.14 1.682.423.483.283.857.661 1.122 1.135.264.474.396.975.396 1.504v3.172c0 .328-.118.61-.355.848-.237.237-.52.355-.848.355H1.203a1.16 1.16 0 01-.848-.355A1.157 1.157 0 010 12.797V9.625c0-.529.132-1.03.396-1.504a2.978 2.978 0 011.122-1.135C2 6.704 2.56 6.563 3.199 6.563a3.793 3.793 0 01-1.011-2.625 3.8 3.8 0 01.533-1.97A4.013 4.013 0 014.156.533 3.8 3.8 0 016.125 0a3.8 3.8 0 011.969.533c.601.356 1.08.834 1.435 1.436a3.8 3.8 0 01.534 1.969 3.793 3.793 0 01-1.012 2.625zM6.126.875c-.547 0-1.057.137-1.531.41a3.008 3.008 0 00-1.121 1.121c-.274.474-.41.985-.41 1.531 0 .547.136 1.058.41 1.532.273.474.647.847 1.12 1.12.475.274.985.411 1.532.411s1.057-.137 1.531-.41a3.008 3.008 0 001.121-1.121 3.01 3.01 0 00.41-1.532c0-.546-.136-1.057-.41-1.53a3.008 3.008 0 00-1.12-1.122 3.013 3.013 0 00-1.532-.41zm5.25 11.922V9.625c0-.602-.214-1.117-.643-1.545a2.106 2.106 0 00-1.544-.643H7.93a3.88 3.88 0 01-1.805.438 3.88 3.88 0 01-1.805-.438H3.062c-.601 0-1.116.215-1.544.643a2.106 2.106 0 00-.643 1.545v3.172c0 .091.032.168.096.232a.316.316 0 00.232.096h9.844a.316.316 0 00.232-.096.316.316 0 00.096-.232z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14"
    }
  };
  _exports.default = _default;
});