define("ember-svg-jar/inlined/steering-wheel-slash-regular", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0_3922_1142)\" fill=\"#000\"><path d=\"M16.125 17.297v4.387a9.757 9.757 0 008.597-8.934h-5.053l-3.483 4.477a.827.827 0 01-.061.07zm-2.25 0c-.019-.024-.042-.047-.06-.07L10.33 12.75H5.278a9.753 9.753 0 008.597 8.934v-4.387zM10.5 10.5l.338-.67c.253-.507.773-.83 1.34-.83h5.644c.567 0 1.087.319 1.34.83l.338.67h5.137C23.916 5.827 19.875 2.25 15 2.25c-4.875 0-8.916 3.577-9.637 8.25H10.5zM15 24a12 12 0 110-24 12 12 0 010 24z\"/><path d=\"M.24.431A1.126 1.126 0 011.818.24l27.75 21.75a1.126 1.126 0 01-1.388 1.772L.431 2.011A1.126 1.126 0 01.24.431z\"/></g><defs><clipPath id=\"clip0_3922_1142\"><path fill=\"#fff\" d=\"M0 0h30v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "30",
      "height": "24",
      "viewBox": "0 0 30 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});