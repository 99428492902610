define("@ember/legacy-built-in-components/index", ["exports", "@embroider/macros/es-compat", "ember"], function (_exports, _esCompat, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextField = _exports.TextArea = _exports.LinkComponent = _exports.Checkbox = void 0;
  /* eslint-disable ember/new-module-imports */

  let Checkbox;
  _exports.Checkbox = Checkbox;
  {
    _exports.Checkbox = Checkbox = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/checkbox")).default;
  }
  let LinkComponent;
  _exports.LinkComponent = LinkComponent;
  {
    _exports.LinkComponent = LinkComponent = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/link-to")).default;
  }
  let TextArea;
  _exports.TextArea = TextArea;
  {
    _exports.TextArea = TextArea = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/textarea")).default;
  }
  let TextField;
  _exports.TextField = TextField;
  {
    _exports.TextField = TextField = (0, _esCompat.default)(require("@ember/legacy-built-in-components/components/text-field")).default;
  }
});