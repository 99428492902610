define("ember-svg-jar/inlined/micro-outline-minus-icon/micro-outline-minus-icon-fill-neutral", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10 0H0v2h10z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "2",
      "viewBox": "0 0 10 2",
      "fill": "#858585"
    }
  };
  _exports.default = _default;
});