define("ember-can/services/abilities", ["exports", "@ember/service", "ember-can/ability", "@ember/debug", "@ember/application", "ember-can/utils/normalize"], function (_exports, _service, _ability, _debug, _application, _normalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class AbilitiesService extends _service.default {
    /**
     * Parse abilityString into an object with extracted propertyName and abilityName
     * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
     * @public
     * @param  {String} string eg. 'create projects in account'
     * @return {Object}        extracted propertyName and abilityName
     */
    parse(abilityString) {
      return (0, _normalize.default)(abilityString);
    }

    /**
     * Create an instance of Ability
     * @public
     * @param  {String} abilityName     name of ability class
     * @param  {*}      model
     * @param  {Object} [properties={}] extra properties (to be set on the ability instance)
     * @return {Ability}                Ability instance of requested ability
     */
    abilityFor(abilityName, model) {
      let properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let AbilityFactory = (0, _application.getOwner)(this).factoryFor(`ability:${abilityName}`);
      (false && !(AbilityFactory) && (0, _debug.assert)(`No ability type found for '${abilityName}'`, AbilityFactory));
      if (typeof model !== 'undefined') {
        properties = _objectSpread({
          model
        }, properties);
      }
      let ability = AbilityFactory.create(properties);
      (false && !(ability instanceof _ability.default) && (0, _debug.assert)(`Ability '${abilityName}' has to inherit from ember-can Ability`, ability instanceof _ability.default));
      return ability;
    }

    /**
     * Returns a value for requested ability in specified ability class
     * @public
     * @param  {String} propertyName name of ability, eg `createProjects`
     * @param  {String} abilityName  name of ability class
     * @param  {*}      model
     * @param  {Object} properties   extra properties (to be set on the ability instance)
     * @return {*}                   value of ability
     */
    valueFor(propertyName, abilityName, model, properties) {
      let ability = this.abilityFor(abilityName, model, properties);
      let result = ability.getAbility(propertyName, model, properties);
      ability.destroy();
      return result;
    }

    /**
     * Returns `true` if ability is permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    can(abilityString, model, properties) {
      let {
        propertyName,
        abilityName
      } = this.parse(abilityString);
      return !!this.valueFor(propertyName, abilityName, model, properties);
    }

    /**
     * Returns `true` if ability is not permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  }
  _exports.default = AbilitiesService;
});