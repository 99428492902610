define("ember-svg-jar/inlined/screwdriver-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M4.124 13.28a1.526 1.526 0 01-2.155 0 1.526 1.526 0 010-2.155l3.27-3.271 2.155 2.155-3.27 3.27zm8.97-11.36l.236.235-1.09 1.814h-.33l-.63-.63v-.331l1.813-1.088zM7.933 8.393l3.124-3.123.223.223h1.824L15.25 1.92 13.329 0 9.756 2.144V3.97l.224.223-3.124 3.123L5.24 5.7.892 10.049a3.05 3.05 0 000 4.31 3.05 3.05 0 004.309-.001l4.348-4.348-1.616-1.616z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});