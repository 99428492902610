define("ember-svg-jar/inlined/expand-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 4.625V.75C0 .542.073.365.219.219A.723.723 0 01.75 0h3.875a.36.36 0 01.266.11C4.964.182 5 .27 5 .375v.25a.362.362 0 01-.11.266.362.362 0 01-.265.109H1v3.625a.362.362 0 01-.11.266A.362.362 0 01.625 5h-.25a.362.362 0 01-.266-.11A.362.362 0 010 4.626zM9.375 0h3.875c.208 0 .385.073.531.219A.723.723 0 0114 .75v3.875a.362.362 0 01-.11.266.362.362 0 01-.265.109h-.25a.362.362 0 01-.266-.11.362.362 0 01-.109-.265V1H9.375a.362.362 0 01-.266-.11A.362.362 0 019 .625v-.25a.36.36 0 01.11-.266A.362.362 0 019.374 0zM14 9.375v3.875a.723.723 0 01-.219.531.723.723 0 01-.531.219H9.375a.362.362 0 01-.266-.11.362.362 0 01-.109-.265v-.25a.36.36 0 01.11-.266.362.362 0 01.265-.109H13V9.375a.36.36 0 01.11-.266.362.362 0 01.265-.109h.25a.36.36 0 01.266.11c.073.072.109.16.109.265zM4.625 14H.75a.723.723 0 01-.531-.219A.723.723 0 010 13.25V9.375a.36.36 0 01.11-.266A.362.362 0 01.374 9h.25a.36.36 0 01.266.11c.073.072.109.16.109.265V13h3.625a.36.36 0 01.266.11c.073.072.109.16.109.265v.25a.362.362 0 01-.11.266.362.362 0 01-.265.109z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});