define("ember-svg-jar/inlined/exchange-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M343.515 508.485l-100-100.375c-4.686-4.686-4.686-12.284 0-16.97l19.626-19.626c4.753-4.753 12.484-4.675 17.14.173L328 422.916V12c0-6.627 5.373-12 12-12h24c6.627 0 12 5.373 12 12v410.916l47.719-51.228c4.656-4.849 12.387-4.927 17.14-.173l19.626 19.626c4.686 4.686 4.686 12.284 0 16.97l-100 100.375c-4.686 4.685-12.284 4.685-16.97-.001zm-192-504.97l-100 100.375c-4.686 4.686-4.686 12.284 0 16.97l19.626 19.626c4.753 4.753 12.484 4.675 17.14-.173L136 89.084V500c0 6.627 5.373 12 12 12h24c6.627 0 12-5.373 12-12V89.084l47.719 51.228c4.656 4.849 12.387 4.927 17.14.173l19.626-19.626c4.686-4.686 4.686-12.284 0-16.97l-100-100.375c-4.686-4.686-12.284-4.686-16.97.001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "16",
      "viewBox": "0 0 512 512"
    }
  };
  _exports.default = _default;
});