define("ember-svg-jar/inlined/empty-circle-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8\" cy=\"8\" fill=\"#fff\" fill-rule=\"evenodd\" r=\"7\" stroke=\"#8c97b2\"/>",
    "attrs": {
      "height": "16",
      "viewBox": "0 0 16 16",
      "width": "16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});