define("ember-svg-jar/inlined/ellipsis-dots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Ellipsis dots</title><desc>Ellipsis dots</desc><circle data-name=\"circle circle--3\" cx=\"38\" cy=\"6\" r=\"6\"/><circle data-name=\"circle circle--2\" cx=\"22\" cy=\"6\" r=\"6\"/><circle data-name=\"circle circle--1\" cx=\"6\" cy=\"6\" r=\"6\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 44 12",
      "aria-describedby": "title description"
    }
  };
  _exports.default = _default;
});