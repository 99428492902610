define("ember-svg-jar/inlined/success-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8.719 0a8.46 8.46 0 014.36 1.178 8.81 8.81 0 013.18 3.181 8.452 8.452 0 011.178 4.36c0 1.57-.392 3.023-1.177 4.36a8.81 8.81 0 01-3.182 3.18 8.452 8.452 0 01-4.36 1.178A8.452 8.452 0 014.36 16.26a8.81 8.81 0 01-3.181-3.182A8.452 8.452 0 010 8.718c0-1.57.393-3.023 1.178-4.359a8.81 8.81 0 013.181-3.181A8.452 8.452 0 018.72 0zm0 16.313a7.41 7.41 0 003.814-1.02 7.559 7.559 0 002.76-2.777 7.472 7.472 0 001.02-3.815c0-1.37-.34-2.637-1.02-3.797a7.559 7.559 0 00-2.777-2.76A7.472 7.472 0 008.7 1.126c-1.37 0-2.637.34-3.797 1.02a7.559 7.559 0 00-2.76 2.777 7.472 7.472 0 00-1.019 3.814c0 1.371.34 2.637 1.02 3.797a7.559 7.559 0 002.777 2.76 7.44 7.44 0 003.797 1.02zm4.992-9.668l-6.363 6.293a.334.334 0 01-.282.14.437.437 0 01-.316-.14l-3.023-3.06a.38.38 0 01-.106-.28c0-.118.035-.223.106-.317L4.043 9a.38.38 0 01.281-.105c.117 0 .223.035.317.105l2.425 2.46 5.73-5.73a.52.52 0 01.317-.105c.117 0 .211.047.282.14l.316.282c.07.094.105.2.105.316a.38.38 0 01-.105.282z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});