define("ember-svg-jar/inlined/skip-forward-solid-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"id-11b\"><use xlink:href=\"#id-10a\"/></clipPath><path transform=\"translate(0 -2)\" id=\"id-10a\" d=\"M7.639 7.098L1.64 2.226C.997 1.704 0 2.141 0 2.978v9.773c0 .837.997 1.277 1.64.751L7.64 8.6a.967.967 0 000-1.502zm7.998 0L9.638 2.229c-.643-.525-1.64-.088-1.64.749v9.773c0 .837.997 1.277 1.64.751L15.637 8.6a.967.967 0 000-1.502z\"/></defs><use xlink:href=\"#id-10a\" stroke-width=\"2\" clip-path=\"url(#id-11b)\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "16",
      "height": "12",
      "viewBox": "0 0 16 12"
    }
  };
  _exports.default = _default;
});