define("ember-svg-jar/inlined/download-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M0 0v14.4h16V1.6H5.931L4.331 0H0zm1.6 1.6h2.068l1.6 1.6H14.4v9.6H1.6V1.6zm3.214 6.791L8 11.211l3.186-2.82-1.06-1.198L8.8 8.366V4.801H7.2v3.565L5.874 7.193l-1.06 1.198z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "15",
      "viewBox": "0 0 16 15"
    }
  };
  _exports.default = _default;
});