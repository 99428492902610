define("ember-svg-jar/inlined/phone-numpad-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.782 11.22l-2.648 1.761A13.688 13.688 0 013.068 6.92L4.82 4.262V.027H.027v.802c0 8.377 6.816 15.198 15.198 15.198h.802v-4.808h-4.245zM1.647 1.625h1.574v2.159L2.326 5.14a13.687 13.687 0 01-.679-3.515zM14.423 14.4a13.508 13.508 0 01-3.515-.678l1.356-.9h2.159V14.4zm-7.2-9.58h1.6v1.6h-1.6v-1.6zm.801-3.2h6.399v1.6H8.024v-1.6zm2.398 3.2h1.6v1.6h-1.6v-1.6zm3.199 0h1.6v1.6h-1.6v-1.6zm-6.398 3.2h1.6v1.599h-1.6v-1.6zm3.199 0h1.6v1.599h-1.6v-1.6zm3.199 0h1.6v1.599h-1.6v-1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17"
    }
  };
  _exports.default = _default;
});