define("ember-svg-jar/inlined/jump-forward-light-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.625 0h-.25C15.169 0 15 .153 15 .34v4.472a.83.83 0 00-.094-.082L8.641.21C7.997-.275 7 .13 7 .908v3.34L1.64.21C.998-.275 0 .13 0 .908V9.98c0 .777.997 1.185 1.64.698L7 6.613V9.98c0 .777.997 1.185 1.64.698l6.266-4.554A.83.83 0 0015 6.043v4.505c0 .187.169.34.375.34h.25c.206 0 .375-.153.375-.34V.34c0-.187-.169-.34-.375-.34zM6.859 5.427l-.006.003-.006.003L1 9.981V.908l5.853 4.514.003.003.003.002zm7.235.015L8.006 9.978l-.003.003H8V.908l.003.002.003.003 6.088 4.503v.026z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "11",
      "viewBox": "0 0 16 11"
    }
  };
  _exports.default = _default;
});