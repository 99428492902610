define("ember-svg-jar/inlined/step-backward-light-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.359 6.786l6 4.981c.643.538 1.64.088 1.64-.769V1.002c0-.857-.997-1.307-1.64-.77l-6 5.02a1 1 0 000 1.534zm.64-.766l6-5.018v10L1 6.02z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "12",
      "viewBox": "0 0 8 12"
    }
  };
  _exports.default = _default;
});